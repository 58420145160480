<template>
  <section id="zoom-session">
    <v-row v-if="meeting && user">
      <v-col cols="12" xl="10">
        <iframe
          style="height: 80vh; width: 100%"
          :src="`/admin/zoom/${meeting.meeting_id}/${meeting.password}/${meeting.admin.zoom_key}/${meeting.admin.zoom_secret}/${user.email}/${user.name}`"
          frameborder="0"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("admin", {
      meeting: (state) => state.meeting,
      user: (state) => state.user,
    }),
  },
  mounted() {
    /**
     * Check user before entering zoom
     *
     */
    this.$store.dispatch("admin/showMeeting", this.$route.params.uuid);
  },
};
</script>

<style>
</style>